
function waitForElms(selector, minimumCount = 1) {
  return new Promise((resolve) => {
    let observer;

    const checkAndResolve = () => {
      const elements = document.querySelectorAll(selector);
      if (elements.length >= minimumCount) {
        if (observer) {
          observer.disconnect();
        }
        resolve(elements);
      }
    };

    if (document.body) {
      checkAndResolve();
    }

    observer = new MutationObserver((mutations) => {
      checkAndResolve();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

waitForElms(".dcAppContainer.active", 1).then((elements) => {
  document.querySelector('#stepOne .dataceed-form-step-actions').style.opacity = 0;
  document.querySelector('#stepOne .dataceed-form-step-actions').style.height = '0px';
  elements.forEach((element) => {
    let energyButtons = document.querySelectorAll('[data-target-element="energy"]');
    energyButtons.forEach(button => {
        button.addEventListener("click", function () {
			setTimeout(function(){	 
				window._dc.stuvdays.goToStep("multistep", "stepTwo");
			}, 100);
        });
    })
  });
  
  
  // Conversions
  
  window._dc.stuvdays.on('event:submit', function() {
  
    // Google Ads
    gtag('event', 'conversion', {
       'value': 'stuvdays'
    });
     
    ga('send', {
       hitType: 'event',
       eventCategory: 'Stuvdays',
       eventAction: 'Conversion',
       eventLabel: '2024'
    });
  
    // Meta
    fbq('track', 'Lead', {value: 'stuvdays'});
    fbq('track', 'lead', {value: 'stuvdays'});
  
  });
  
});

// dirty hack to force language in stuvdays popin

setInterval(function() {
  var langInput = document.querySelector('.dataceed-form input[name=lang]');
  if (langInput) {
    if (langInput.value.length !== 2) {
      window._pc.stuvdays.setValue('lang', _dcGetLang());
    }
  }
}, 1000);


// Display after 5sec 
function getSessionCookie(name) {
  let cookieArr = document.cookie.split(";");

  for (let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split("=");

    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }

  return null;
}

function setSessionCookie(name, value) {
  document.cookie = name + "=" + (value || "") + "; path=/";
}

function openStuvDays() {
    switch (true) {
        case window.location.pathname.includes('obtenir-un-devis'):
        case window.location.pathname.includes('een-offerte-aanvragen'):
        case window.location.pathname.includes('get-a-quote'):
        case window.location.pathname.includes('ottenere-un-preventivo'):
        case window.location.pathname.includes('node/5329'):
        case window.location.pathname.includes('node/5563'):
        case window.location.pathname.includes('node/4801'):
        case document.documentElement.getAttribute("lang") == 'es': 
        case document.documentElement.getAttribute("lang") == 'it': 
        case document.documentElement.getAttribute("lang") == 'pt': 
        case document.documentElement.getAttribute("lang") == 'de': 
            break;
    
        default:
            if (!getSessionCookie("stuvDays")) {
                //window._pc.stuvdays.open(document.documentElement.getAttribute("lang"));
                window._dc.stuvdays.open(_dcGetLang());
                setSessionCookie("stuvDays", "true");
            }
            break;
    }
}

setTimeout(openStuvDays, 5000);
